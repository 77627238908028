import { ezBookingApi } from "@/axios.js"
export default {
    async getUserProfile(user) {
        try {
            let response = await ezBookingApi.get(`/booking/users/${user['mail']}`)
            if (!response['data']['user']) {
                let bodyForCreate = JSON.parse('{}')
                bodyForCreate['cmuitaccount'] = user['mail']
                bodyForCreate['full_name'] = user['displayName']
                let userObj = await this.createNewUserSuperSaaS(bodyForCreate)
                return userObj['data']['user']
            } else {
                return response["data"]['user']
            }
        } catch (error) {
            console.log(error.message)
            return false
        }
    },
    async createNewUserSuperSaaS(data) {
        try {
            let response = await ezBookingApi.post(`/booking/users`, data)
            return response
        } catch (error) {
            return false
        }
    },
    async getScheduleList() {
        try {
            let arrList = []
            let response = await ezBookingApi.get(`/booking/schedules`)
            if (response["status"]) {
                for (let schedule of response["data"]["data"]) {
                    let obj = schedule
                    let _arrStr = schedule["name"].split(" ")
                    obj["shortLink"] = _arrStr.join("_")
                    arrList.push(obj)
                }
            }
            return arrList
        } catch (error) {
            return false
        }
    },
}