<template>
  <p>กำลังเข้าสู่ระบบ...</p>
</template>

<script>
import authO365 from "@/services/AuthO365"
import apiEzBooking from "@/services/apiEzBookingServices"

export default {
  name: "Callback",
  data() {
    return {
      redirectUri: `${process.env.VUE_APP_REDIRECT_URI}`,
      adminList: ["nuttapong.pongkam@cmu.ac.th", "thapakorn.pe@cmu.ac.th"],
    }
  },
  created() {
    // console.log("this.$route.query.code >" , this.$route.query.code)
    this.filterUser(this.$route.query.code)
  },
  methods: {
    async filterUser(queryCode) {
      let resUser = await authO365.getUserProfile(this.redirectUri, queryCode)
      // console.log("checkResUser>" , resUser)
      if (resUser[`status`]) {
        let _user = resUser[`user`]
        localStorage.setItem(`user`, JSON.stringify(_user))
        let superSaasData = await apiEzBooking.getUserProfile(_user)
        localStorage.setItem(`superSaasUser`, JSON.stringify(superSaasData))
        this.$router.push({ path: "/select-room" })
      } else {
        this.$router.push({ path: "/signin" })
        console.log("false sign in ")
      }
    },
  },
}
</script>
