<template>
<p>กำลังออกจากระบบ...</p>
</template>

<script>
export default {
  name: 'Signout',
  data(){
    return {
    }
  },
  created() {
      console.log("signout")
      localStorage.removeItem('user')
      window.location.replace(`https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}/oauth2/v2.0/logout`)
  },
}
</script>
