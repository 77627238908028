import axios from "axios"
const basicAuthSaas = "Basic " + btoa(process.env.VUE_APP_SAAS_USER + ":" + process.env.VUE_APP_SASS_PASSWORD)
const basicAuthEzBooking = "Basic " + btoa(process.env.VUE_APP_EZBOOKING_API_USER + ":" + process.env.VUE_APP_EZBOOKING_API_PASSWORD)
export const SUPERSAAS = axios.create({
    baseURL: process.env.VUE_APP_SUPERSAAS_API,
    headers: {
        Authorization: basicAuthSaas,
    },
})

export const authConnector = axios.create({
    baseURL: process.env.VUE_APP_EZBOOKING_AUTHEN_API,
    headers: {},
})

export const ezBookingApi = axios.create({
    baseURL: process.env.VUE_APP_EZBOOKING_API,
    headers: {
        Authorization: basicAuthEzBooking,
    },
})