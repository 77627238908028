import Vue from "vue"
import VueRouter from "vue-router"
import SelectRoomCaledar from "../views/SelectRoomCalendar.vue"
import Callback from "../views/Callback.vue"
import SignInO365 from "../views/Signin.vue"
import SignOutO365 from "../views/Signout.vue"

Vue.use(VueRouter)
// change file name
const routes = [{
        path: "/",
        name: "Home",
        component: SelectRoomCaledar,
    }, {
        path: "/select-room",
        name: "SelectRoomCaledar",
        component: SelectRoomCaledar,
    },
    {
        path: "/signin",
        name: "Signin",
        component: SignInO365,
    },
    {
        path: "/signout",
        name: "Signout",
        component: SignOutO365,
    },
    {
        path: "/callback",
        name: "Callback",
        component: Callback,
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})


router.beforeEach((to, from, next) => {
    let user = localStorage.getItem("user")
    if (to.name !== "Signin" && to.name !== "Callback" && user === null) {
        next({ name: "Signin" })
    } else {
        next()
    }
})
export default router